import { IsIn, IsInt, IsNumber, IsOptional, IsString, Max, ValidateNested, IsArray, ArrayNotEmpty } from 'class-validator';

import { CrudPaginateRequest } from '../crud/CrudPaginateRequest';
import { TPDateModel, TPDateModelRaw } from '../third-party/TPDateModel';
import { MAX_INT_VALUE } from '../utils/consts';

import { OrderStatusTxt } from './order-status';

export interface OrderGetListRequestRaw {
    contrId?: number | string;
    orderId?: number | string;
    userId?: number | string;
    areaId?: number | string;
    status?: OrderStatusTxt[];
    totalSum?: number | string;
    sumRange?: number | string;
    pickupAddress?: string;
    comment?: string;
    date?: TPDateModelRaw | TPDateModel;
    dateRange?: number;
    paginateInfo?: CrudPaginateRequest;
}

export class OrderGetListRequest {
    @IsOptional()
    @IsInt()
    public contrId?: number;

    @IsOptional()
    @IsInt()
    @Max(MAX_INT_VALUE)
    public orderId?: number;

    @IsOptional()
    @IsInt()
    public userId?: number;

    @IsOptional()
    @IsInt()
    public areaId?: number;

    @IsOptional()
    @IsArray()
    @ArrayNotEmpty()
    @IsIn(['new', 'pending', 'work', 'closed', 'shipped'] satisfies OrderStatusTxt[], { each: true })
    public status?: OrderStatusTxt[];

    @IsOptional()
    @IsNumber()
    public totalSum?: number;

    @IsOptional()
    @IsInt()
    public sumRange?: number;

    @IsOptional()
    @IsString()
    public pickupAddress?: string;

    @IsOptional()
    @IsString()
    public comment?: string;

    @IsOptional()
    public date?: TPDateModel;

    @IsOptional()
    @IsInt()
    public dateRange?: number;
    //
    // @IsOptional()
    // @IsBoolean()
    // public loadPositions?: boolean;

    @ValidateNested()
    public paginateInfo?: CrudPaginateRequest;

    constructor(data?: OrderGetListRequestRaw) {
        this.contrId = parseInt(data?.contrId as string, 10) || null;
        this.orderId = parseInt(data?.orderId as string, 10) || null;
        this.userId = parseInt(data?.userId as string, 10) || null;
        this.areaId = parseInt(data?.areaId as string, 10) || null;
        this.status = data?.status;
        this.totalSum = parseFloat(data?.totalSum as string) || null;
        this.sumRange = parseInt(data?.sumRange as string, 10) || null;
        this.pickupAddress = data?.pickupAddress;
        this.comment = data?.comment;
        this.date = data?.date && (data.date instanceof TPDateModel ? data.date : new TPDateModel(data.date));
        this.dateRange = data?.dateRange;
        // this.loadPositions = data.loadPositions;
        /** @see CrudItemListRequest */
        this.paginateInfo = data?.paginateInfo && new CrudPaginateRequest(data.paginateInfo);
    }
}
